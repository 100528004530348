import '../static/css/f7icons.css'
import '../static/css/fonts.css'

window.connectUrl = 'https://connect.orastream.com'
//window.connectUrl = 'http://localhost'
window.showsUrl = 'https://shows-connect.orastream.com'
//window.cdnUrl = 'https://brio.orastream.com'
//window.showsUrl = 'https://connect.solivee.com'
window.cdnUrl = 'https://cdn.solivee.com'
window.apiUrl = connectUrl + '/api'

window.latestVersion = '0.4.0'

window.ownerId = `62748d6091f4129a6646d3f4`
window.ownerName = 'Solivee'
window.brandName = 'Solivee'


const loadJs = src => {
    return new Promise(resolve => {    
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        script.onload = resolve
        document.body.appendChild(script)
    })
}

(async () => {
    await loadJs('js/os.js')
    await loadJs('js/os.shows.js')    
    await loadJs('js/capacitor.js')

    if (Capacitor?.platform == 'ios')
        history.replaceState(null, null, '/?ios.html' )

    if (Capacitor?.platform == 'android')
        history.replaceState(null, null, '/?android.html')

    if (/electron/i.test(navigator.userAgent))
        history.replaceState(null, null, location.pathname.replace('index.html', 'index.html?electron.html'))

    console.log('capacitor:', Capacitor?.platform, location.href)
    
    await Capacitor.Plugins.Keyboard.setAccessoryBarVisible({ isVisible: true }).catch(e => {})

    let userInfo = await _OS.get('userInfo')
    if (!userInfo || !userInfo.id) await new Promise(r => setTimeout(r, 3000))    

    let err
    await import('./main').catch(e => err = e)
    if (err) return alert('This web browser is not supported. Please use another web browser.') 

    await import('./methods')

    let all = []
    all.push(loadJs('js/pcm-to-mp4.js'))
    all.push(loadJs('js/socket.io.js'))
    all.push(loadJs('js/notification.min.js'))
    all.push(loadJs('js/ga.js'))

    await Promise.all(all)


    if (/(android|ios).html/.test(location.href))
        await loadJs('js/os.mobile.js')        

    if (/electron/.test(location.href)) 
        await loadJs('js/os.electron.js')

    $$(document).focus()

    console.log('version:', f7.version)
})()